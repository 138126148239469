"use client";

import { useAuthenticator } from "@/hooks/useAuth";

import Spinner from "@/components/Loading/PageLoading";
import { User } from "@/services/server/data/UserData.service";
import { Center } from "@chakra-ui/react";
import { Companies, CompanySubscriptions } from "@/models";
import { find } from "lodash";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { createCookie } from "./actions";
import { CurrentUserProvider } from "./contexts/currentUserContext";

const notProtectedRoutes: string[] = ["/auth/signout", "/auth", "/start", "/tag"];
export function isProtectedRoute(pathname: string | null) {
  if (pathname === null) return false;
  return find(notProtectedRoutes, (route) => pathname.startsWith(route)) === undefined;
}

const AuthContext = ({
  children,
  user,
  company,
  subscriptions,
}: {
  children: React.ReactNode;
  user: User | null;
  company: Companies | null;
  subscriptions: CompanySubscriptions[] | null;
}) => {
  const { id_token, authStatus, expired } = useAuthenticator();
  const router = useRouter();
  const isLoading = authStatus === "loading";
  const pathname = usePathname();

  const authenticated = authStatus === "authenticated";

  const isProtected = isProtectedRoute(pathname);

  useEffect(() => {
    if ((user === null || expired) && isProtected) {
      const currentPath = window.location.pathname + window.location.search;

      createCookie("redirectUrl", currentPath);

      router.push("/auth");
    }
  }, []);

  const redirecting = (user === null || expired) && isProtected;

  return (
    <div>
      {isLoading || redirecting ? (
        <Center height={"100vh"}>
          <Spinner />
        </Center>
      ) : (
        <CurrentUserProvider user={user} company={company} subscriptions={subscriptions}>
          {children}
        </CurrentUserProvider>
      )}
    </div>
  );
};

export default AuthContext;
