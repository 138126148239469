// theme/themeConfig.ts
import type { ThemeConfig } from "antd";

export const Primary_Theme_color = "#000000";
export const Primary_Accent_color = "#F93535";
export const BitterSweet_red = "#FF5B5B";
export const White_color = "#FFFFFF";
export const Neutral_White = "#FAFAFA";
export const Neutral_Grey = "#F2F2F2";
export const Breaker_Grey = "#C5CCD3";
export const Hot_Black = "#0B1215";

const antTheme: ThemeConfig = {
  token: {
    colorPrimary: Primary_Accent_color,
    colorInfoBg: Neutral_Grey,
    colorInfo: Hot_Black,
  },
  components: {
    Button: {
      paddingInline: "20px",
      contentFontSize: 15,
      primaryShadow: "none",
    },
    Input: {
      fontSize: 15,
      colorPrimaryHover: Primary_Theme_color,
      activeBorderColor: Primary_Theme_color,
    },
    InputNumber: {
      fontSize: 15,
      colorPrimaryHover: Primary_Theme_color,
      activeBorderColor: Primary_Theme_color,
    },
    Layout: {
      siderBg: Primary_Theme_color,
      headerBg: "#ffffff",
      triggerBg: Primary_Theme_color,
    },
    Card: {
      borderRadiusLG: 16,
    },
  },
};

export default antTheme;
