"use client";

import ErrorMessage from "@/components/ErrorMessage/ErrorMessage";
import { useAuthenticator } from "@/hooks/useAuth";
import { SubscriptionType } from "@/models";
import { User } from "@/services/server/data/UserData.service";
import { Center } from "@chakra-ui/react";
import { Companies, CompanySubscriptions, UserStatuses } from "@/models";
import { message } from "antd";
import { filter, find, isNil } from "lodash";
import { signOut, useSession } from "next-auth/react";
import { usePathname, useRouter } from "next/navigation";
import React, { createContext, useEffect, useState } from "react";
import { clearCookie, getCookie } from "../actions";
import { getCurrentUser } from "../apiCalls/user/usersCRUD";
import { isProtectedRoute } from "../authContext";

interface CurrentUserContextType {
  loadingContext: boolean;
  currentUser: User | null;
  currentCompany: Companies | null;
  companySubscriptions: CompanySubscriptions[] | null;
  maxSeatCount: number | null;
  companyIsOnTrial: boolean;
  hasActiveSubscription: boolean;
  activeSubscription: CompanySubscriptions | null;
  hasFlexSubscription: boolean;
  isSuperAdmin: boolean;
  validSubscription: boolean;
  hasEnterpriseSubscription: boolean;
  isDemoAccount: boolean;
  refreshUserContext: () => Promise<{ user; company; subscriptions }>;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  loadingContext: false,
  currentUser: null,
  currentCompany: null,
  companySubscriptions: null,
  maxSeatCount: 0,
  companyIsOnTrial: false,
  hasActiveSubscription: false,
  activeSubscription: null,
  hasFlexSubscription: false,
  isSuperAdmin: false,
  validSubscription: true,
  hasEnterpriseSubscription: false,
  isDemoAccount: false,
  refreshUserContext: async () => {
    return { user: null, company: null, subscriptions: [] };
  },
});

interface ProviderProps {
  // authData: CognitoUser;
  user: User | null;
  company: Companies | null;
  subscriptions: CompanySubscriptions[] | null;
  children: React.ReactNode;
}

export const CurrentUserProvider = ({ children, user, company, subscriptions }: ProviderProps) => {
  // State
  const { id_token, authStatus } = useAuthenticator();
  const router = useRouter();
  const session = useSession();
  const [currentUser, setCurrentUser] = useState<User | null>(user);
  const [loadingContext, setLoadingContext] = useState(false);
  const _activeSubscription = find(subscriptions, (sub) => sub.isActive) as CompanySubscriptions;
  const [currentSubscription, setCurrentSubscription] = useState<CompanySubscriptions | null>(_activeSubscription);
  const [currentCompany, setCurrentCompany] = useState<Companies | null>(company);
  const [currentSubscriptions, setCurrentSubscriptions] = useState<any>(subscriptions);
  const userLoaded = currentUser && currentUser?.id !== undefined;
  const pathname = usePathname();

  // Computed
  const activeCompanySubscriptions =
    filter(currentSubscriptions, (subscription) => subscription.isActive === true) || [];

  const companyIsOnTrial = activeCompanySubscriptions?.some(
    (subscription) => subscription.type === SubscriptionType.Trial,
  );

  const maxSeatCount = activeCompanySubscriptions?.reduce((total, subscription) => total + subscription.seatCount, 0);

  const isDemoAccount = activeCompanySubscriptions.some((subscription) => subscription.type === SubscriptionType.Demo);

  const hasActiveSubscription = activeCompanySubscriptions?.length > 0;

  const hasFlexSubscription = activeCompanySubscriptions.some(
    (subscription) => subscription.type === SubscriptionType.Flex,
  );

  const hasEnterpriseSubscription = activeCompanySubscriptions.some(
    (subscription) => subscription.type === SubscriptionType.Enterprise,
  );

  const isSuperAdmin = currentUser?.isSuperAdmin || ((currentUser?.isAdmin && currentCompany?.isCondoit) as boolean);
  const userIsInactive =
    currentUser && userLoaded && currentUser.isActive === false && session.status === "authenticated";

  const userIsMissingFields =
    currentUser && (!currentUser.firstName || !currentUser.lastName || !currentUser.acceptedTerms);
  const userIsAwaitingApproval = !userIsMissingFields && currentUser?.status === UserStatuses.AWAITING_APPROVAL;

  const [validSubscription, setValidSubscription] = useState(hasActiveSubscription);
  // TODO - Need to set up subscriptions to watech the user, company, and company subs to ensure
  // that the data is always up to date

  const refreshUserContext = async (): Promise<{
    user: null;
    company: null;
    subscriptions: [];
  }> => {
    return await fetchUser();
  };

  useEffect(() => {
    if (currentUser) return;
    if (loadingContext) return;
    fetchUser();
  }, [id_token]);

  useEffect(() => {
    if (currentUser === null) return;
    validateRoute();
  }, [currentUser, currentCompany, currentSubscriptions]);

  useEffect(() => {
    validateRoute();
  }, [pathname]);

  const isProjectShareRoute = pathname?.startsWith("/share/");

  async function validateRoute() {
    if (loadingContext || isNil(user)) return;

    if (authStatus === "unauthenticated") return;

    if (isProjectShareRoute) return;

    const protectedRoute = isProtectedRoute(pathname);
    const upgradeRoute = pathname?.includes("get-started");
    const isAuthRoute = pathname?.startsWith("/auth");
    const companyIsMissingFields = currentCompany && !currentCompany.name;
    const userMissingSubscriptions =
      !currentSubscriptions || currentSubscriptions.length === 0 || !hasActiveSubscription;

    const redirectUrl = await getCookie("redirectUrl");
    await clearCookie("redirectUrl");

    if (redirectUrl && redirectUrl !== "/") {
      router.push(redirectUrl);
    } else if ((userIsMissingFields || companyIsMissingFields) && !isAuthRoute) {
      router.push("/get-started");
    } else if (userMissingSubscriptions && !upgradeRoute && protectedRoute && !isAuthRoute && !userIsAwaitingApproval) {
      setValidSubscription(false);
      message.destroy("invalid-sub-message");
      message.error({
        content: "Your company does not have a valid subscription",
        key: "invalid-sub-message",
        duration: 5000,
      });
      if (currentUser?.isAdmin) {
        router.push("/settings/profile/company");

        setTimeout(() => {
          message.destroy("invalid-sub-message");
          router.push("/get-started/choose-plan");
        }, 5000);
      } else {
        router.push("/invalidsubscription");
      }
    } else {
      if (window.location.pathname === "/") {
        console.log("redirecting to projects ", Date.now());
        router.push("/projects");
      }
    }
  }

  const fetchUser = async (): Promise<{
    user: null;
    company: null;
    subscriptions: [];
  }> => {
    try {
      const startTime = Date.now();
      console.log("Fetching User");
      const { user, company, subscriptions } = await getCurrentUser();

      setLoadingContext(true);
      if (!user) {
        throw new Error("Current user not found");
      }

      if (!company) {
        throw new Error("Current company not found");
      }

      setCurrentUser(user);
      setCurrentCompany(company);
      setCurrentSubscriptions(subscriptions);
      const _activeSubscription = subscriptions.find((subscription) => subscription.isActive === true);
      setCurrentSubscription(_activeSubscription);

      const activeCompanySubscriptions = filter(subscriptions, (subscription) => subscription.isActive === true) || [];

      setValidSubscription(activeCompanySubscriptions?.length > 0);

      const endTime = Date.now();
      console.log("User Context fetched in: ", endTime - startTime, "ms");

      return { user, company, subscriptions };
    } catch (error) {
      console.log("error fetching user from server: ", error);
      return { user: null, company: null, subscriptions: [] };
    } finally {
      setLoadingContext(false);
    }
  };

  function invalidUser() {
    if (userIsInactive) {
      return (
        <div className="pt-4">
          <Center>
            <ErrorMessage
              title="Account Deactivated"
              message={`The account ${user?.email} has been deactivated. Please contact your administrator for more information.`}
              buttonAction={signOut}
              buttonText="Sign out"
            />
          </Center>
        </div>
      );
    } else if (userIsAwaitingApproval) {
      return (
        <div className="pt-4">
          <Center>
            <ErrorMessage
              title="Awaiting approval"
              message={`Your account needs to be approved to join ${company?.name}.\nCondoit support has been notified to get approval from your company's administrator.`}
              buttonAction={signOut}
              buttonText="Sign out"
            />
          </Center>
        </div>
      );
    } else if (!currentUser?.isAdmin && !validSubscription) {
      return (
        <div className="pt-4">
          <Center>
            <ErrorMessage
              title="Invalid Subscription"
              message="Your subscription is invalid. Please contact your administrator or upgrade your plan to continue using Condoit."
              buttonAction={signOut}
              buttonText="Sign out"
            />
          </Center>
        </div>
      );
    } else {
      return <>something went wrong</>;
    }
  }

  const isUserInvalid =
    !isProjectShareRoute &&
    userLoaded &&
    (userIsInactive || (!currentUser?.isAdmin && !validSubscription) || userIsAwaitingApproval);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        currentCompany,
        companySubscriptions: currentSubscriptions,
        maxSeatCount,
        companyIsOnTrial,
        hasActiveSubscription,
        hasFlexSubscription,
        activeSubscription: currentSubscription,
        refreshUserContext: refreshUserContext,
        validSubscription: validSubscription,
        isSuperAdmin,
        loadingContext,
        hasEnterpriseSubscription,
        isDemoAccount,
      }}
    >
      {isUserInvalid ? invalidUser() : children}
    </CurrentUserContext.Provider>
  );
};
